import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInbox, faRobot, faEnvelopeOpenText, faBars, faDoorOpen } from '@fortawesome/free-solid-svg-icons'

interface Props {
  fixed?: Boolean;
  children?: JSX.Element | JSX.Element[];
}


const NavBar = ({ children }: Props) => {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between py-0 bg-bott">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a
              className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
              href="/"
            >
              AttBott
            </a>
            <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"><FontAwesomeIcon icon={faBars} /></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="/inbox"
                >
                  <i className="text-lg leading-lg text-white opacity-75"><FontAwesomeIcon icon={faInbox} /></i><span className="ml-2">Inbox</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="/attbotts"
                >
                  <i className="text-lg leading-lg text-white opacity-75"><FontAwesomeIcon icon={faRobot} /></i><span className="ml-2">AttBotts</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="/master-plan"
                >
                  <i className="text-lg leading-lg text-white opacity-75"><FontAwesomeIcon icon={faEnvelopeOpenText} /></i><span className="ml-2">Master Plan</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="/login"
                >
                  <i className="text-lg leading-lg text-white opacity-75"><FontAwesomeIcon icon={faDoorOpen} /></i><span className="ml-2">Login</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
        <main>
          {children}
        </main>
      </div>
    </>
  )
}

export default NavBar
