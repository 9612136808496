import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
//import { setContext } from '@apollo/client/link/context';

type Props = {
  didToken: string;
  children?: JSX.Element | JSX.Element[];
};

// see https://www.apollographql.com/docs/react/api/link/introduction/#handling-a-response
// this explanation shines some light on how to use ApolloLinks to modify requests
// but doesn't explain how to use setContext() from '@apollo/client/link/context';
// some idea came from https://nextsteps.dev/apollo-client-graphQL-and-auth

// basically we create a chain of Apollo links authLink -> httpLink to add the
// authentication token to the request before sending it. but there is a catch,
// might not have to token so we need to do an asynchronous call to
// getAccessTokenSilently(). that will return a promise with the token.

// TODO: store the token in Session storage so we can survive a reload

// TODO: use batch http link to optimize fetching times and load to database see
// https://www.apollographql.com/docs/react/api/link/apollo-link-batch-http/

let appURL: string = process.env.REACT_APP_APP_URL ?? "";

const AuthorizedApolloProvider = ({ didToken, children }: Props) => {
  const [theApolloClient, setApolloClient] = useState(
    new ApolloClient({ // By default we'll go with no auth. 
      uri: appURL + "/api/v1/graphql",
      cache: new InMemoryCache(),
      credentials: 'include',
    })
  )

  const [cookies] = useCookies();

  useEffect(() => {
    let jwtToken = cookies['magiclink_cookie'] || ""
    console.log("This are the tokens we have: '" + jwtToken + "' and '" + didToken + "'")
    if (jwtToken !== "") {
      console.log("2")
      setApolloClient(
        new ApolloClient({
          uri: appURL + "/api/v1/graphql",
          cache: new InMemoryCache(),
          credentials: 'include',
        })
      )
    } else if (didToken !== "") { // if didToken changes, then let's create a new client,
      setApolloClient(
        new ApolloClient({
          uri: appURL + "/api/v1/graphql",
          cache: new InMemoryCache(),
          credentials: 'include',
          headers: {
            'Authorization': 'Bearer ' + didToken,
          }
        })
      )
    }
  }, [didToken, cookies])


  return (
    <ApolloProvider client={theApolloClient}>
      {children}
    </ApolloProvider>
  );
};

export default AuthorizedApolloProvider;