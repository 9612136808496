import SubscriptionDetail from '../subscriptions/SubscriptionDetail';
import { Subscription } from '../subscriptions/types';

interface Props {
    id: string;
    name: string;
    description: string;
    type: string;
    image: string;
    frequency: number;
    created_at: string;
    subscriptions?: Array<Subscription>;
}

const AttBottSummary = ({ id, name, description, image, frequency, created_at, subscriptions }: Props) => {
    return (
        <div className="flex justify-center items-center">

            <div className="m-6 p-6 bg-white rounded-xl shadow-xl md:w-1/2">
                <div className="flex">
                    <div className="flex flex-col justify-center">
                        <div className="p-1 border-4 rounded-full">
                            <img className="h-16 w-16 object-cover rounded-full" src={image} alt={name} />
                        </div>
                    </div>
                    <div className="flex flex-col ml-6 justify-center">
                        <div className="text-2xl font-bold text-gray-700">{name}</div>
                        <div className="text-base text-gray-700 italic">{description}</div>
                    </div>
                </div>
                <div className="flex flex-col mt-6">
                    <div className="text-xs text-gray-500">Frequency: {frequency}</div>
                    <div className="text-xs text-gray-500">Created: {created_at}</div>
                </div>
                <div className="mt-8">
                    {subscriptions && subscriptions.length > 0 && <div className="text-lg font-semibold text-gray-700">Current subscriptions ({subscriptions.length}):</div>}
                    {subscriptions && subscriptions.length > 0 && subscriptions.map(s =>
                        <SubscriptionDetail key={s.id} {...s} />)}
                </div>
            </div>
        </div>
    )
}
export default AttBottSummary;