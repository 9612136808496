import React from 'react'
import CardHeader from './CardHeader'

interface Props {
    post: string
    poster: string
    poster_img?: string
    created_at: string
}

const Card = ({ post, poster, poster_img, created_at }: Props) => {

    return (
        <div className="max-w-full mx-auto rounded-xl shadow-xl border-2 p-2 m-5 border-gray-300">
            <CardHeader
                poster={poster}
                poster_img={poster_img}
                created_at={created_at}
                frequency={60}
            />
            <div className="max-w-full mx auto rounded-xl border-3 p-1 bg-gray-100 border-gray-800 ">
                {post}
            </div>

        </div>
    )
}

export default Card;
