import { gql } from "@apollo/client";

export const INSERT_SUBSCRIPTION = gql`
mutation ($data: subscriptionInput!){
  subscriptions(insert: $data, delete:false) {
    id
  }
}`;

export interface NewSubscription {
    id: string;
}

export interface NewSubsctiptionDetails {
    user_id: string;
    attbott_id: string;
    type: string;
    mode: string;
    time_of_day_match: string;
}