import { Magic } from 'magic-sdk';
//import Callback from '../components/authentication/Callback';
import { UserContextInterface } from '../context/userContext';

var publishableKey: string;
if (typeof process.env.REACT_APP_MAGIC_LINK_PUBLISHABLE_KEY === "undefined") {
  // TODO: make a component out of this
  alert("ERROR: REACT_APP_MAGIC_LINK_PUBLISHABLE_KEY must be defined")
  publishableKey = "";
} else {
  publishableKey = process.env.REACT_APP_MAGIC_LINK_PUBLISHABLE_KEY;
}

export const magic = new Magic(publishableKey);

export const logoutUser = async () => {
  await magic.user.logout();
};

export const loginUser = async (email: string) => {
  await magic.auth.loginWithMagicLink({ email });
};

export const checkUser = async (cb: (user: UserContextInterface) => void) => {
  const isLoggedIn = await magic.user.isLoggedIn();
  //const isLoggedIn = true;
  console.log("checkUser");

  if (isLoggedIn) {
    const user = await magic.user.getMetadata();
    // I could be logged in without an email! i.e. metamask
    // maybe we need to review this logic
    console.log("isLoggedIn evals to true");
    return cb({ isLoggedIn: true, email: user.email || "" });
  }
  console.log("isLoggedIn evals to false");
  return cb({ isLoggedIn: false, email: "" });
};