import { Magic } from 'magic-sdk';

var publishableKey: string;
if (typeof process.env.REACT_APP_MAGIC_LINK_PUBLISHABLE_KEY === "undefined") {
    // TODO: make a component out of this
    // so we can do return <div>error REACT_APP_AUTH0_DOMAIN must be defined</div>
    alert("ERROR: REACT_APP_MAGIC_LINK_PUBLISHABLE_KEY must be defined")
    publishableKey = "";
} else {
    publishableKey = process.env.REACT_APP_MAGIC_LINK_PUBLISHABLE_KEY;
}

export const magic = new Magic(publishableKey);