interface Props {
    description: string;
}

const Message = ({ description }: Props) => {
    return (
        <div className="flex justify-center my-4">
            <div className="flex justify-center bg-red-500 rounded p-4 w-1/2">
                <div className="text-white font-bold text-xl">{description}</div>
            </div>
        </div>
    );
};

export default Message