import React, { FormEvent, useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client';
import { useHistory } from "react-router-dom"
// Form that asks the user to confirm their username and timezone.

interface Props {
  // FIXME add user id
  email: string
}

const MODIFY_USER = gql`
mutation ($email: String!, $data: usersInput!){
  users(where: {email: {eq: $email}}, update: $data, delete:false) {
    id
    username
  }
}`;

interface UserInventory {
  id: string;
  username: string;
}
interface NewUserDetails {
  username: string;
  email?: string;
}

function validateUsername(username: string): string {
  if (username.length < 3) {
    return "Username has to be at least 3 letters";
  }
  return "OK";
}

const UserOnboarding = ({ email }: Props) => {
  const [nextEnabled, setNextEnabled] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameValid, setUsernameValid] = useState("Username has to be at least 3 letters");
  const [usernameConfirmValid, setUsernameConfirmValid] = useState("Confirm your username. Usernames can't be changed. Ever.");
  let history = useHistory();

  const [modifyUser, { error, data }] = useMutation<
    { saveUser: UserInventory },
    { data: NewUserDetails, email: string }
  >(MODIFY_USER, {
    variables: { data: { "username": username }, email: email }
  });
  const onChangeUsername = useCallback(event => {
    // TODO: check that the username is valid in database
    let result = validateUsername(event.target.value);
    if (result === "OK") {
      setUsername(event.target.value);
      setUsernameValid("");
      setNextEnabled(true);
    } else {
      setUsername("");
      setUsernameValid(result);
      setNextEnabled(false);
    }
  }, []);

  const onChangeConfirmUsername = useCallback(event => {
    if (event.target.value === username) {
      setUsernameConfirmValid("")
      setNextEnabled(true);
    } else {
      setUsernameConfirmValid("Confirm your username. Usernames can't be changed. Ever.")
      setNextEnabled(false);
    }
  }, [username]);

  const next = (event: FormEvent) => {
    event.preventDefault();
    modifyUser().then(() => {
      history.push("/inbox");
    }).catch((error: any) => {
      setUsernameValid(error) //FIXME maybe a separate state for error?
    });
  }

  return (
    <div>
      <form>
        <div className="form-group">
          <label>Username</label>
          <input type="text" onChange={onChangeUsername} className="form-control" placeholder="Username" />
          {usernameValid !== "" ? <div className="alert alert-danger">{usernameValid}</div> : ""}
        </div>
        <div className="form-group">
          <label>Confirm Username</label>
          <input type="text" onChange={onChangeConfirmUsername} className="form-control" placeholder="Confirm Username" />
          {usernameConfirmValid !== "" ? <div className="">{usernameConfirmValid}</div> : ""}
        </div>
        <div className="form-group">
          <label>Timezone</label>
          <select className="form-control" >
            <option value="America/New_York">America/New_York</option> {/* TODO: add more timezones */}
            <option value="America/Chicago">America/Chicago</option>
            <option value="America/Denver">America/Denver</option>
            <option value="America/Los_Angeles">America/Los_Angeles</option>
            <option value="America/Anchorage">America/Anchorage</option>
            <option value="Pacific/Honolulu">Pacific/Honolulu</option>
          </select>
        </div>
        <div className="form-group">
          <button type="submit" className="" disabled={!nextEnabled} onClick={next}>Next</button>
        </div>
      </form>
      {error ? <p>Oh no! {error.message}</p> : null}
      {data && data.saveUser ? <p>Saved!</p> : null}
    </div>
  )
}

export default UserOnboarding