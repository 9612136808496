import React, { FormEvent, useState, useCallback } from "react";
import { useMutation, useQuery } from '@apollo/client';
import AttBott from "../attention_bots/AttBottSummary";
import Message from "../common/Message";
import { useHistory } from "react-router-dom"
import { AttBottData, AttBottVars, GET_SINGLE_ATTBOTT } from "../attention_bots/queries";
import { INSERT_SUBSCRIPTION, NewSubscription, NewSubsctiptionDetails } from "./queries";

interface Props {
  id: string;
}

const SubscriptionForm = ({ id }: Props) => {
  const [savingError, setSavingError] = useState("");
  const [timeOfDayMatch, setTimeOfDayMatch] = useState("7:00");

  const { loading: loadingCards, error: errorCards, data: cards } = useQuery<AttBottData, AttBottVars>(
    GET_SINGLE_ATTBOTT,
    {
      variables: {
        id,
      }
    }
  );

  // FIXME: This is a hack. We should be able to get the user's id / email from the session.
  let user_id = "5d6d51a8-f899-4178-ae24-39da6d83b6f0";

  const [addSubscription, { error, data }] = useMutation<
    { saveSubscription: NewSubscription },
    { data: NewSubsctiptionDetails }
  >(INSERT_SUBSCRIPTION, {
    variables: { data: { user_id: user_id, "attbott_id": id, "type": "time-of-day", "mode": "latest", "time_of_day_match": timeOfDayMatch } }
  }
  );
  const onChangeTimeOfDay = useCallback(event => {
    setTimeOfDayMatch(event.target.value);
  }, []);


  let history = useHistory();
  const next = (event: FormEvent) => {
    event.preventDefault();
    addSubscription().then(() => {
      history.push("/inbox");
    }).catch((error: any) => {
      setSavingError(error) //FIXME maybe a separate state for error?
    });
  }

  if (errorCards) return <div>Error! ${errorCards.message}</div>; // make some pretty error
  if (loadingCards) return <div>Loading...</div>;

  if (cards !== undefined && cards.attention_bots) {
    return (
    <div className="bg-gray-100">
      {cards && cards.attention_bots.map(card =>
        <AttBott key={card.id} {...card} />
      )}
      <div className="flex justify-center">
        <div className="flex flex-col">
          <div className="text-lg font-semibold text-gray-700">WHEN</div>
          <form onSubmit={next}>
            <div className="flex items-end">
              <div className="flex flex-col">
                <div>Time of day</div>
                <select className="form-control w-24 h-10" onChange={onChangeTimeOfDay}>
                  <option value="7:00">7:00</option>
                  <option value="8:00">8:00</option>
                  <option value="9:00">9:00</option>
                  <option value="10:00">10:00</option>
                  <option value="11:00">11:00</option>
                  <option value="12:00">12:00</option>
                  <option value="13:00">13:00</option>
                  <option value="14:00">14:00</option>
                  <option value="15:00">15:00</option>
                  <option value="16:00">16:00</option>
                  <option value="17:00">17:00</option>
                  <option value="18:00">18:00</option>
                  <option value="19:00">19:00</option>
                  <option value="20:00">20:00</option>
                  <option value="21:00">21:00</option>
                  <option value="22:00">22:00</option>
                  <option value="23:00">23:00</option>
                </select>
              </div>
              <button type="submit" className="ml-4 bg-bottSecondary hover:bg-opacity-50 h-10 text-white text-xs font-bold px-4 rounded inline-flex items-center" onClick={next}>
                NEXT
              </button>
            </div>
          </form>
          <div className="my-8">
            {error ? <p>Oh no! {error.message}</p> : null}
            {data && data.saveSubscription ? <p>Saved!</p> : null}
            {timeOfDayMatch ? <p>Every day at {timeOfDayMatch}</p> : null}
            {savingError ? <p>Oh no! {savingError}</p> : null}
            TODO: ADD "YOUR TIMEZONE IS..."
          </div>
        </div>
      </div>
    </div>
    
  )
  } else {
    let message = "No AttBotts with id "+id;
    return (
      <Message description={message} /> 
    )
  }

}

export default SubscriptionForm;