function frequency2Human(seconds: number) {
// input is in seconds
    if (seconds > 24*3600) {
        return "every " + Math.floor(seconds/60*24*3600) + " days";
     }
     if (seconds === 24*3600) {
        return "every day";
     }
     if (seconds > 3600) {
        return "every " + Math.floor(seconds/60*24) + " hours";
     }
     if (seconds === 3600) {
        return "every hour ago";
     }
     if (seconds === 1800) {
        return "every Half an hour ago";
     }
     if (seconds > 60) {
        return "every " + Math.floor(seconds/60) + " minutes";
     }
     if (seconds === 60) {
        return "every minute";
     }

     return "every " + seconds + "seconds"
}

export default frequency2Human