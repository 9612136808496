import React from "react";
import SubscriptionForm from "../components/subscriptions/SubscriptionForm";
import { useParams } from 'react-router-dom';

const SubscribePage = () => {
    let { id } = useParams();

    if (id == null || id === "" || id === undefined) {
        return <div>No id provided</div>;
    } else {
        return <SubscriptionForm id={id} />;
    }
}

export default SubscribePage;
