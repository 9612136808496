import React, { useState, useEffect } from "react";
import * as dotenv from 'dotenv';
//import { Route, Switch } from "react-router";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';
// Views
import { createBrowserHistory } from 'history';

import NavBar from './components/NavBar';

import InboxPage from './pages/InboxPage';
import WelcomePage from './pages/WelcomePage';
import MasterPlan from './pages/MasterPlan';

import Callback from "./components/authentication/Callback";
import AuthorizedApolloProvider from "./components/authentication/AuthorizedApolloProvider";
import Login from "./components/authentication/Login";
import AttBottPage from "./pages/AttBottPage";
import SubscribePage from "./pages/SubscribePage";
export const history = createBrowserHistory();

// Load .env file as environment
dotenv.config();

// getSessionStorageOrDefault will try to get values from the session storage
// or will return the default value. 
// idea from: https://typeofnan.dev/using-session-storage-in-react-with-hooks/
function getSessionStorageOrDefault(key: string, defaultValue: any) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}



export default function App() {
  // At the top level will store the DID Token (distributed identity) and get it
  // from session if it exists
  //
  const [didToken, setDidToken] = useState(
    getSessionStorageOrDefault("didToken", '""') //get it from session or use stringified empty string
  );

  // Update the session storage as soon as the did token gets updated (Normally
  // the Login button will do this.)
  //
  useEffect(() => {
    sessionStorage.setItem('didToken', JSON.stringify(didToken));
  }, [didToken]);


  return (
    <CookiesProvider> {/* graphJin will store a cookie if already signed */}
      {/* Don't forget to add the history to your router */}
      <BrowserRouter history={history}>
        {/* This should be common to all */}
        <NavBar>
          <div className="text-gray-600 font-body">
            <Switch>
              <Route path="/" exact>
                <WelcomePage>
                  <Login handleDIDTokenUpdate={setDidToken} />
                </WelcomePage>
              </Route>
              <Route path="/master-plan" exact>
                <MasterPlan>
                  <Login handleDIDTokenUpdate={setDidToken} />
                </MasterPlan>
              </Route>
              <Route path="/callback" exact >
                <Callback handleDIDTokenUpdate={setDidToken} />
              </Route>
              <Route path="/login" exact>
                <Login handleDIDTokenUpdate={setDidToken} />
              </Route>
              <AuthorizedApolloProvider didToken={didToken}>
                <Route path="/attbotts/:id" component={SubscribePage} />
                <Route path="/attbotts" exact component={AttBottPage} />
                <Route path="/inbox" exact component={InboxPage} />
              </AuthorizedApolloProvider>

            </Switch>
          </div>
        </NavBar>
      </BrowserRouter>
    </CookiesProvider>
  );
}

