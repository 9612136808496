import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
//import DIDTokenContext from "../context/DIDTokenContext";
import { magic } from "../../magic";

interface Props {
  handleDIDTokenUpdate: React.Dispatch<React.SetStateAction<string>>;
}
// loggedout -> logginin -> loggedin
export default function Login({ handleDIDTokenUpdate }: Props) {
  const [email, setEmail] = useState("");
  const [isLoggedState, setLoggedState] = useState("loggedout");
  const history = useHistory();

  //think hard how to do a context / state of a logged in user.
  //  const loginState = useContext(DIDUserContext);
  /**
   * Perform login action via Magic's passwordless flow. Upon successuful
   * completion of the login flow, a user is redirected to the inbox.
   */

  // FIXME ... is the MEMOIZED version of this function needed? is it making a
  // mistake with expired tokens?

  const login = useCallback(async () => {
    setLoggedState("logginin");

    try {
      let freshDidToken = await magic.auth.loginWithMagicLink({
        email,
        redirectURI: new URL("/callback", window.location.origin).href,
      });
      if (freshDidToken !== null) {
        handleDIDTokenUpdate(freshDidToken);
        setLoggedState("loggedin");
      }
      history.push("/inbox");
    } catch {
      setLoggedState("loggedout");
    }
  }, [email, history, handleDIDTokenUpdate]);

  /**
   * Saves the value of our email input into component state.
   */
  const handleInputOnChange = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  if (isLoggedState === "loggedin") {
    return <div>Logout</div>;
  }

  if (isLoggedState === "loggingin") {
    return <div>Login in....</div>;
  }

  return (
    <div className="flex">
      <div className="py-8 m-auto">
        <h1>Please sign up or login:</h1>
        <input
          type="email"
          name="email"
          required
          placeholder="Enter your email"
          onChange={handleInputOnChange}
        />
        <button
          className="rounded-full ml-4 py-2 px-3 uppercase text-xs font-bold cursor-pointer tracking-wider border-2 border-bott hover:bg-gray-200"
          onClick={login}
        >
          Send
        </button>
      </div>
    </div>
  );
}
