//TODO: investigate moment.js

function time2TimeAgo(ts: string) {
    // This function computes the delta between the
    // provided timestamp and the current time, then test
    // the delta for predefined ranges.

    var d=new Date();  // Gets the current time
    var nowTs = Math.floor(d.getTime()/1000); // getTime() returns milliseconds, and we need seconds, hence the Math.floor and division by 1000
    var eventTs = Math.floor(Date.parse(ts)/1000);
    var seconds = nowTs-eventTs;
    
    // more that two days
    if (seconds > 2*24*3600) {
       return "a few days ago";
    }
    // a day
    if (seconds > 24*3600) {
       return "yesterday";
    }

    if (seconds > 3600) {
       return "a few hours ago";
    }
    if (seconds > 1800) {
       return "Half an hour ago";
    }
    if (seconds > 60) {
       return Math.floor(seconds/60) + " minutes ago";
    }
    // We can't parse it... just return as is. Maybe in the future
    // translate from RFC 3339 to something more human-friendly.
    return ts
}

export default time2TimeAgo;