import { Subscription } from "./types"

const SubscriptionDetail = (props: Subscription) => {
    return (
    <div className="flex flex-col mt-2"> 
        <div className="text-base">{props.id}</div>
        <div className="flex justify-between">
            <div className="text-xs">type: {props.type} {props.time_of_day_match}</div>
            <div className="text-xs">mode: {props.mode}</div>
        </div>
        <div className="mt-2 border-b-2 border-dotted"></div>
    </div>
    );
}

export default SubscriptionDetail