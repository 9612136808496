import React from 'react'

interface Props {
    children?: JSX.Element | JSX.Element[];
}

const WelcomePage = ({ children }: Props) => {
    return (
        <div className="grid justify-items-center text-justify">
            <article className="prose-xl max-w-md mt-16">
                <div>
                    Move from a corporate-controlled-algorithm centric
                    and instant <i>interruption systems</i> to a
                    <span className="group text-bott ">
                        <span className="group-hover:font-extrabold">&nbsp;self-subscription&nbsp;</span>
                        <span className="text-bott hidden group-hover:inline transition duration-700 delay-75 ease-out">(You subscribe to notifications to show up when <b>you</b> need them. Not all the time, whenever <i>they</i> want)&nbsp;</span>
                    </span>
                    selective interruption system.
                </div>

                <div className="ring-2 ring-bott ring-opacity-40 rounded-full py-2 mt-5 px-4 bg-bott text-gray-100 text-center">
                    <a href="master-plan" >Read our <span className="text-white bg-bott font-bold">Master Plan</span></a>
                </div>
            </article>
            {children}
        </div>
    )
}

export default WelcomePage
