import React from 'react'

interface Props {
    children?: JSX.Element | JSX.Element[];
}

const MasterPlan = ({ children }: Props) => {
    return (
        <div className="grid justify-items-center text-justify p-4">
            <article className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl mt-8">
                <div>
                    <h1>The Secret AttBott Master Plan (just between you and me)</h1>
                    <div className="mt-4 italic">
                        Background: I’m a Systems Engineer and a user of computers, I use them daily more than I want to admit. I get a lot of interruptions ona a daily basis and I see real harm in the world’s population's mental health because of this. It’s so ubiquitous that future generations will not be able to distinguish the voluntary/internally triggered from the external interruptions.  We have to gain control of our lives. This is our secret plan. </div>
                    <div className="mt-4">Don’t tell anyone.</div>
                    <div className="mt-4">
                        The current interaction with news, social platforms, instant messaging and email-centric work has a lot of interruptions. Some of them are incentivized by the companies’ profits that runs those platforms, and they “take advantage of the vulnerability of human psychology”, or in some cases carelessly just notifying us for anything that happens, all the time, regardless the context or priority or relevance for us. </div>
                    <div className="mt-4 font-bold">
                        The overarching purpose of AttBott is to move from a algorithmic-controlled-instant interruption systems to a self-subscription interruption system.</div>
                    <div className="mt-4">
                        AttBott will coexist with other interruption systems, if we can’t replace them all at least we should be an “Interruption Oasis” in terms of control and productivity at the user's hands, and a way to give useful interruptions when emotional discomfort kicks in instead of mindless scrolling and distractions.</div>
                    <h2>Interruptions is not the same as distractions. Interruptions are good, as long as they are in context to handle them.</h2>
                    <div className="mt-4">
                        The goal is to create context aware actionable interruptions… not yet another distraction. At the right context (time, place, activity or a combination of all), offer the list of customizable actions.</div>
                    <div className="mt-4">
                        This means that today we need to classify the interruptions, and organize them to know when to take care of them. That’s called a subscription. Is the [type-of-interruption, when-to-be-interrupted] tuple that we need to control. </div>
                    <div className="mt-4">
                        Today in many platforms either:
                        <ol>
                            <li> We control the type of notification (i.e. I only subscribe to crypto news on twitter) but we don’t control when to be interrupted because they are instantaneous interruptions, creating distraction or.. </li>
                            <li> We control when to be interrupted (like opening our email inbox) but it is a bag of all sorts of notifications, that in the incorrect context they end up being distractions.</li>
                        </ol>
                    </div>
                    <h2>Here are some interesting questions to ask ourselves.</h2>
                    <div className="mt-4">
                        <ul>
                            <li>What is the average time a person spends reading notifications? And doing nothing? And snoozing doing nothing later?</li>
                            <li>What is the cost of those interruptions in terms of producing a bigger outcome?</li>
                            <li>From all the social networks (Facebook,  Twitter, Reddit, etc) choose the one that causes you the most disrupting interruption. Why it's so effective at sucking up all your time?</li>
                            <li>How can we create a compound effect on expanding the platform while using the platform? (eat your own cooking for exponential growth)</li>
                        </ul>
                    </div>

                    <h2>Handling the nature of notifications.</h2>
                    <div className="mt-4">
                        Notifications have a wide range of how public they are: from World wide news that everybody gets identical copies of them to small groups of friends and family notifications, only relevant to few individuals. </div>
                    <div className="mt-4">
                        Notifications have a wide range of tailoring: and from horizontal content (i.e.  weather forecast) to very vertical/niche content (Auctions in your area of stuffed albino pandas for collectors).</div>
                    <div className="mt-4">
                        Notifications can have a lot of information, but we should be able to provide summary versions and different formats to present the data (thumbnails, video snippet, visual representation, audio). Making it possible to create a good user experience depending on the time/context when they want to consume the notification.</div>
                    <div className="mt-4">
                        AttBott will be able to handle all these dimensions of notifications efficiently.</div>

                    <h2>Making it easier to create a notification, but hard to annoy users.</h2>
                    <div className="mt-4">
                        Attention Bots are the programs that create the notifications. Should be trivial and easy as writing a small Python or Bash script. And it could be anything: big news about some niche community we're part of, some customer feedback our small business got, some weather information about our 2 day trip next week to the beach.</div>
                    <div className="mt-4">
                        Attention Bots do not get to decide when the user is interrupted. Users do. And this is a hard line, because the purpose of the platform is to create selective interruption as we stated before.</div>

                    <h2>Integrate customizable premade actions to notifications.</h2>
                    <div className="mt-4">
                        Notifications are read-only. It’s hard to think that after reading a piece of news “what’s the next action?”. Most of the time we just read it (or even worse half read it skimming through because it’s in front of us at the wrong time/context to actually read it). </div>
                    <div className="mt-4">
                        On the other hand some notifications <b>do</b> require action, but they get postponed because the context is not adequate to execute them, or even worse: they are repetitive and boring.</div>
                    <div className="mt-4">
                        AttBott will change this, creating an easy-to-dispatch action.  This last part is important, actions should be rich and effortless. Most of our productivity in the future will be competing with machines. Some parts of labor made by humans today will be replaced by machines with the progressive inclusion of AI in the workforce. This is unavoidable, for costs and efficiency reasons.</div>
                    <div className="mt-4">
                        AttBott will have to embrace this and facilitate humans the power of AI by offering actions and executing them on their behalf. The decisions will still be done by the users, but the execution will be done by machines. The decision to automate decisions will be left to each individual, this will be a tradeoff between control and productivity.  Also multi-step actions that need several notifications-action iterations</div>

                    <h2>Leverage with computers.</h2>
                    <div className="mt-4">
                        What are the Best things humans do? What are the best things bots do? Make a way to maximize the best on both sides of the system. Drop us a line what are your thoughts on this</div>
                    <h2>AttBott platform as a future consumer product</h2>
                    <div className="mt-4">
                        The initial phases of the project will not cover being a Web-scale service, but if the desire is to do so several changes have to be made to incentivize growth and lower the running costs. Attention bot subscriptions will have to be paid and some revenue has to go to the creators of the attention bots. This could use the leverage of blockchain technology and traditional markets to make it global.</div>
                    <div className="mt-4">
                        Actions will have to be able to execute in private environments. Since some of the notifications will be private/protected, the execution of the subsequent actions will have to be private too. This will require integration with 3rd parties APIs or the ability to run backend processes in private environments.</div>
                    <div className="mt-4">
                        Attention Bots will evolve over time. Having full control over the versions users want to be subscribed to is a needed feature for users. Easy upgrades to newer versions must be available.</div>
                    <div className="mt-4">
                        Spam is real, and Attention Bots have a chance to become spam machines. To avoid this, clever anti-spam mechanisms must be in place to flag, alert (and  potentially ban from the platform) rogue Bots.</div>


                    <h2>TL;DR</h2>
                    <div className="mt-4">
                        <ul>
                            <li>Give the user control of their interruptions.</li>
                            <li>Awesome notification display, search and filtering.</li>
                            <li>Rich and effortless actions triggered by interruptions.</li>
                            <li>Machines will help automate only if the user decides to.</li>
                            <li>Optional Marketplace and execution in private environments.</li>
                        </ul>
                    </div>
                    <div className="mt-4">Don't tell anyone.</div>

                </div>
            </article>
            {children}
        </div>
    )
}

export default MasterPlan
