import { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { AttBottData, GET_ATTBOTTS } from './queries';

const AttBottList = () => {
    const { loading: loadingCards, error: errorCards, data: cards } = useQuery<AttBottData>(
        GET_ATTBOTTS,
    );

    const history = useHistory();

    const subscribe = (attbott_id: string) => {
        history.push("/attbotts/" + attbott_id);
    }

    return (
        <Fragment>
            {errorCards && (
                <div>Error! ${errorCards.message}</div>
            )}

            {loadingCards && (
                <div>Loading...</div>
            )}

            {cards === undefined && (
                <div>No AttBotts!</div>
            )}

            {cards !== undefined && cards.attention_bots && (
                <div className="flex justify-center items-center">

                    <div className="flex flex-col m-6 p-6 bg-white rounded-xl shadow  lg:w-2/3">
                        {cards && cards.attention_bots.map(card =>
                            <div key={card.id} className="flex items-center justify-between border-b-2 border-gray-200 border-dotted py-4">
                                <div className="flex">
                                    <div className="flex flex-row justify-center">
                                        <div className="p-1 border-4 rounded-full">
                                            <img className="h-12 w-12 object-cover rounded-full" src={card.image} alt={card.name} />
                                        </div>
                                    </div>
                                    <div className="flex flex-col ml-6 justify-center">
                                        <div className="text-base font-bold text-gray-700">{card.name}</div>
                                        <div className="text-xs text-gray-700 italic">{card.description}</div>
                                    </div>
                                </div>
                                <div className="flex-0">
                                    <button className="bg-bottSecondary hover:bg-opacity-50 text-white text-xs font-bold py-1 px-5 rounded inline-flex items-center" onClick={() => subscribe(card.id)}>
                                        <i className="text-lg leading-lg text-white opacity-75"><FontAwesomeIcon icon={faCaretRight} /></i><span className="ml-2">SUBSCRIBE</span>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            )}
        </Fragment>
    );
};

export default AttBottList;