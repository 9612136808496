import { gql } from '@apollo/client';
import { IAttBott } from './types';

export const GET_ATTBOTTS = gql`
  query GetAttBotts {
    attention_bots {
      id
      name
      description
      image
      frequency
      created_at
    }
  }
`;

export interface AttBottData {
    attention_bots: IAttBott[];
}

export interface AttBottVars {
    id: string;
}


export const GET_SINGLE_ATTBOTT = gql`
    query GetSingleAttBotts($id: string!) {
      attention_bots (where: {id: {_eq: $id}}) {
        id
        name
        description
        image
        frequency
        created_at
        subscriptions {
          id
          user_id
          attbott_id
          type
          mode
          time_of_day_match
        }
      }
    }
  `;