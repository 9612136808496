import { User } from '../authentication/types';

interface Props {
    user?: User
}

const InboxHeader = ({ user }: Props) => {
    return (
        <div className="flex justify-end bg-bott">
            <div className="text-gray-100">{user?.username} &lt;{user?.email}&gt;
            </div>
        </div>
    )
}

export default InboxHeader;
