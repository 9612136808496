import React from 'react'
import time2TimeAgo from '../../utils/time2TimeAgo'
import freq2Human from '../../utils/frequency2Human'
interface Props {
    poster: string
    poster_img?: string
    created_at: string
    frequency: number
}

const CardHeader = ({ poster, poster_img, created_at, frequency }: Props) => {
    const ts = time2TimeAgo(created_at)
    const someTimeAgo = freq2Human(frequency)
    // redo this from https://youtu.be/vqKie-xmcFs

    return (
        <div className="flex">
            <div className="flex-none  h-24 hover:opacity-100 opacity-25 ">
                <img className="h-24 w-24 object-cover rounded-full float-left" src={poster_img} alt="" />
                {poster}
            </div>
            <div className="flex-grow "></div>
            <div className="flex-none  opacity-25 ">{ts} (updated {someTimeAgo})</div>
        </div>
    )
}

export default CardHeader
