import { useQuery, gql, } from '@apollo/client';
import Card from '../user_cards/Card';
import InboxHeader from './InboxHeader';
import UserOnboarding from '../authentication/UserOnboarding';
import { User } from '../authentication/types';

interface AnInCard {
  post: string
}
interface AUserCard {
  id: string;
  in_card: AnInCard;
  created_at: string;
}

interface CardData {
  user_cards: AUserCard[];
}
interface UserData {
  users: User[];
}
interface UserVars {
  myuser: string;
}

const GET_USER = gql`
  query GetUser {
    users {
        email
        username
    }
  }
`

const GET_CARD = gql`
  query GetCard {
    user_cards {
      id
      in_card {
        post
      }
      created_at
    }
  }
`;

const Inbox = () => {
  const { loading: loadingCards, error: errorCards, data: cards } = useQuery<CardData, UserVars>(
    GET_CARD,
  );

  const { loading: loadingUser, error: errorUser, data: user } = useQuery<UserData, UserVars>(
    GET_USER,
  );

  if (errorCards) return <div>Error! ${errorCards.message}</div>; // make some pretty error
  if (errorUser) return <div>Error! ${errorUser.message}</div>;

  if (user !== undefined && user?.users[0].username === null) {
    return <UserOnboarding email={user?.users[0].email} />;
  }

  if (loadingCards || loadingUser) {
    return <div>Loading...</div>
  }


  if (cards !== undefined && cards.user_cards) {
    return <div className="max-w-full mx-auto bg-white place-items-stretch">
      {user && <InboxHeader user={user.users[0]} />}
      <div className="content-center px-4">
        {cards && cards.user_cards.map(card => (
          <Card key={card.id}
            post={card.in_card.post}
            poster="N/A"
            poster_img="N/A"
            created_at={card.created_at}
          />
        ))}
      </div>
    </div>
  } else {
    return <div>No Data for user</div>
  }
}

export default Inbox;