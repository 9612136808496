import React, { useEffect } from "react";
import { magic } from "../../services/magic";
import { useHistory } from "react-router-dom";
interface Props {
  handleDIDTokenUpdate: React.Dispatch<React.SetStateAction<string>>;
}

export default function Callback({ handleDIDTokenUpdate }: Props) {
  const history = useHistory();
  useEffect(() => {
    // On mount, we try to login with a Magic credential in the URL query.
    magic.auth.loginWithCredential().then((token: string | null) => {
      if (token != null) {
        // FIXME: Delete the cookie?
        handleDIDTokenUpdate(token);
        history.push("/inbox");
      } else {
        console.log("No token");
      };
    }).catch((error: any) => {
      return <div>Error: {error}</div>
    })
  }, [handleDIDTokenUpdate, history]);

  return <div>Loading...</div>;
}